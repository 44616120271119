import Layout from "../components/Layout";
import NavThree from "../components/NavThree";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import React from "react";
import PageDetails from "../components/PageDetails";
import useCookies from "../Hooks/useCookies";

const Cookies = () => {
    const cookiesData = useCookies();
    return (
        <Layout pageTitle="Kipso | News Details">
            <NavThree/>
            <PageHeader title="Cookies"/>
            <PageDetails body={cookiesData}/>
            <Footer/>
        </Layout>
    );
};

export default Cookies;
