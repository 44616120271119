import {graphql, useStaticQuery} from "gatsby"


const useCookies = () => {
    const response = useStaticQuery(
        graphql`
            query cookies {
                strapiCookies {
                    BodyContent
                }
            }
        `
    )
    return response.strapiCookies.BodyContent;
}

export default useCookies;
